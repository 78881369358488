"use client";

import { Next13ProgressBar } from "@/progressbar";
import { getImgProps } from "@/utils";
import { HistoryInterceptorProvider, NextPropsProvider } from "@palette.tools/react";
import React, { Suspense } from "react";


export default function LayoutClient({ children} : { children: React.ReactNode }) {

  return <NextPropsProvider getImgProps={getImgProps}>
    <HistoryInterceptorProvider>
      {children}
    </HistoryInterceptorProvider>
    <Suspense>
      <Next13ProgressBar
        height="4px"
        color="#C860E3"
        delay={1000}
        showOnShallow
        options={{
          showSpinner: true
        }}
      />
    </Suspense>
  </NextPropsProvider>
}
