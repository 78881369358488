import { rgbDataURL } from "@palette.tools/utils";
import { getImageProps } from 'next/image'


export function getImgProps(props: any) {
  const { props: {...rest} } = getImageProps({...props, unoptimized: process.env.NODE_ENV !== "production" })
  return props;
}


export function getOrigin(request: Request, localHost: string = "localhost:3000") {
  let protocol = "https:";
  let host = request ? request.headers.get("x-forwarded-host") || request.headers.get("host") : undefined;
  if (!host) {
    throw new Error("No host found in request");
  }

  if (host.indexOf("localhost") > -1) {
    if (localHost) host = localHost;
    protocol = "http:";
  }

  return `${protocol}//${host}`;

}


// New function to generate Data URL from Tailwind color
export const tailwindColorToDataURL = (colorName: string): string | null => {
  // Create temporary element to apply class and compute style
  const tempElement = document.createElement('div');
  tempElement.className = `bg-${colorName}`;
  document.body.appendChild(tempElement);

  // Get computed style
  const computedStyle = getComputedStyle(tempElement);
  const bgColor = computedStyle.backgroundColor;

  // Remove the temporary element
  document.body.removeChild(tempElement);

  // Extract RGB values from computed style
  const match = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/.exec(bgColor);
  if (match) {
    const [_, r, g, b] = match;
    return rgbDataURL(parseInt(r), parseInt(g), parseInt(b));
  } else {
    return null;
  }
};
